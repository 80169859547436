export const yesNoSelect = [
  {
    id: true,
    displayName: "Tak",
  },
  {
    id: false,
    displayName: "Nie",
  },
];

export const laboratories = [
  { id: "GENOMED_SA", displayName: "GENOMED_SA" },
  { id: "DF_MEDICA_POLSKA_SP_J", displayName: "DF_MEDICA_POLSKA_SP_J" },
  { id: "LABORATORIUM_ANALITYKI_MEDYCZNEJ_ADLAB", displayName: "LABORATORIUM_ANALITYKI_MEDYCZNEJ_ADLAB" },
  { id: "MEDYCZNE_LABORATORIUM_DIAGNOSTYKA", displayName: "MEDYCZNE_LABORATORIUM_DIAGNOSTYKA" },
  {
    id: "MEDYCZNE_LABORATORIUM_DIAGNOSTYKA_ZYCZKOWSKIEGO_KRAKOW",
    displayName: "MEDYCZNE_LABORATORIUM_DIAGNOSTYKA_ZYCZKOWSKIEGO_KRAKOW",
  },
  { id: "CAMBRIDGE_DIAGNOSTICS", displayName: "CAMBRIDGE_DIAGNOSTICS" },
  { id: "LABORATORIUM_MASDIAG", displayName: "LABORATORIUM_MASDIAG" },
  { id: "CM_VITAIMMUN", displayName: "CM_VITAIMMUN" },
  { id: "AL_MED", displayName: "AL_MED" },
  { id: "LIFELINE_DIAG", displayName: "LIFELINE_DIAG" },
  { id: "BADAMY_GENY", displayName: "BADAMY_GENY" },
  { id: "TEST_DNA_LABORATORIUM", displayName: "TEST_DNA_LABORATORIUM" },
  { id: "INSTYTUT_GENETYKI_SADOWEJ_SP_ZOO", displayName: "INSTYTUT_GENETYKI_SADOWEJ_SP_ZOO" },
  { id: "GENXONE_SA", displayName: "GENXONE_SA" },
  { id: "ALAB", displayName: "ALAB" },
  { id: "FULGENT", displayName: "FULGENT" },
  { id: "HEALTH_LABS", displayName: "Health Labs sp. z o.o." },
  { id: "LABORATORIUM_BADAN_TOKSYKOLOGICZNYCH", displayName: "Laboratorium Badań Toksykologicznych Sp. z o.o." },
  { id: "GENMED", displayName: "GenMed Laboratorium Diagnostyki Molekularnej" },
  { id: "DIAGNOSTYKA_LABORATORIUM_JUTRZENKI", displayName: "Diagnostyka laboratorium PPM ul. Jutrzenki" },
  { id: "HELISA", displayName: "Helisa Medyczne Laboratorium Diagnostyczne" },
  { id: "CENTRUM_BADAN_DNA", displayName: "Centrum Badań DNA Sp. z o.o." },
  { id: "INSTYTUT_MIKROEKOLOGII", displayName: "Instytut Mikroekologii" },
];

export const taxRates = [
  { id: "TAX_0", displayName: "ZW" },
  { id: "TAX_5", displayName: "5" },
  { id: "TAX_8", displayName: "8" },
  { id: "TAX_23", displayName: "23" },
];

export const orderWorkflows = [
  { id: "NEW_LEAD", displayName: "New Lead" },
  { id: "NEW_COMMENT", displayName: "New Comment" },
  { id: "NEW_ORDER", displayName: "Nowe zamówienie - default" },
  { id: "ADB_NEW_ORDER", displayName: "Nowe Zamówienie - Adb Lab" },
  {
    id: "LABORATORIUM_BADAN_TOKSYLOGICZNYCH_NEW_ORDER",
    displayName: "Nowe Zamówienie - Laboratorium Badan Toksylogicznych",
  },
  { id: "NEW_ORDER_WITHOUT_LAB", displayName: "Nowe Zamówienie - Bez laboratorium" },
  { id: "NEW_DIAGNOSTYKA_WITHOUT_LAB", displayName: "Nowe Zamówienie - Diagnostyka, Bez laboratorium" },
  { id: "FOOD_WITHOUT_LAB", displayName: "Nowe Zamówienie - Cambridge Lab" },
  { id: "FOODSCREEN_WITHOUT_LAB", displayName: "Nowe Zamówienie - Vitaimmun bez Lab" },
  { id: "LIFELINE_DIAG_NEW_ORDER", displayName: "Nowe Zamówienie - Lifeline Diag" },
  { id: "NEW_BADAMY_GENY_WITHOUT_LAB", displayName: "Nowe Zamówienie - Badamy Geny bez Lab" },
  { id: "NEW_TESTDNA_LABORATORIUM", displayName: "Nowe Zamówienie - TESTDNA" },
  { id: "FULGENT_GENETICS", displayName: "Nowe Zamówienie - Fulgent Genetics" },
  { id: "EMPTY_WORKFLOW", displayName: "Pusty workflow" },
];

export const productVariants = [
  {
    id: "STANDARD",
    displayName: "Standardowy",
  },
  {
    id: "SUPLEMENT",
    displayName: "Suplement",
  },
  {
    id: "CUSTOM",
    displayName: "Indywidualnie wyceniany",
  },
];

export const productFamilyTypes = [
  {
    id: "MEDICAL_EXAMINATIONS",
    displayName: "Badania",
  },
  {
    id: "DRUGS_AND_SUPPLEMENTS",
    displayName: "Leki i suplementy",
  },
  {
    id: "PHYSICAL_PRODUCTS",
    displayName: "Produkty fizyczne",
  },
  {
    id: "VIRTUAL_PRODUCTS",
    displayName: "Produkty wirtualne",
  },
  {
    id: "CONSULTATIONS",
    displayName: "Konsultacje",
  },
  {
    id: "NON_STANDARD_PRODUCTS",
    displayName: "Produkt niestandarowy",
  },
];

export const getDisplayNameFromProductFamilyTypes = (id) => {
  for (const productFamilyType of productFamilyTypes) {
    if (productFamilyType.id === id) {
      return productFamilyType.displayName;
    }
  }
  return "?";
};

export const productAttributeTypes = [
  {
    id: "STRING_VALUE",
    displayName: "Etykieta",
  },
  {
    id: "BOOLEAN_VALUE",
    displayName: "Prawda / Fałsz",
  },
  {
    id: "SELECT_VALUE",
    displayName: "Pole wyboru",
  },
  {
    id: "NUMERIC_VALUE",
    displayName: "Wartość numeryczna",
  },
];
